$leasePurple = #25215E
$leaseBlue = #245391
$leaseGreen = #31A142
$white = #fff
$font-stack = 'Source Sans Pro', helvetica, arial, sans-serif
$font-stack-headers = 'Open Sans Condensed', helvetica, arial, sans-serif

.coming-soon
    margin auto
    width fit-content

h1,h2,h3,h4,h5
    font-family $font-stack-headers
    font-weight 600
+below(500px)
    h2
        font-size 1.5rem

body
    font-family $font-stack

.container-wide
    max-width 1720px
.em-header-wrapper
    padding 15px 0
    .container-wide
        border-bottom 5px solid $leasePurple
    .em-header
        display flex
        flex-flow row wrap
        justify-content space-evenly
        width 100%
        .header-column
            width 50%
            padding 25px
            +below(991px)
                width 100%
            &.em-description
                line-height 1.7
                max-width 500px
                p
                    color $leasePurple
                    font-weight: 500
            &.logo
                img
                    max-width 100%
                +below(991px)
                    img
                        display block
                        margin auto

.callouts
    margin-bottom 50px
    .callout-heading
        text-align center
        color $leaseGreen
        padding 25px 0
    .callout-container
        display flex
        flex-flow row nowrap
        justify-content space-evenly
        +below(991px)
            flex-wrap wrap
        .callout
            position: relative;
            width 50%
            border-radius 10px
            +below(991px)
                width 100%
            h2
                text-align center
                padding 50px 50px 25px
            &.offer-financing
                border 5px solid $leasePurple
                +above(992px)
                    margin-right 15px
                button
                    background-color $leasePurple
                    &:hover, &.focus
                        color $leaseGreen
                h2
                    color $leaseGreen
                #vendor-email
                    display block
                    border 2px solid $leasePurple
                    width auto
                    margin auto
                    margin-bottom 25px
                    &::placeholder
                        color $leasePurple
                #email-validate
                    display none
                    color tomato
                    margin auto
                    text-align center
                    margin-bottom 10px
            &.get-financing
                border 5px solid $leaseGreen
                +below(991px)
                    margin-top 30px
                +above(992px)
                    margin-left 15px
                button
                    background-color $leaseGreen
                    &:hover, &.focus
                        color $leasePurple
                    +above(992px)
                        position: absolute;
                        bottom: 0
                h2
                    color $leasePurple
            button
                width 100%
                border none
                color $white
                font-size 40px
                font-weight 600
                &:focus, &:hover
                    cursor pointer
                    outline none
                +below(991px)
                    margin: -1px;
                    width: calc(100% + 2px);

.how-it-works
    background-color $leasePurple
    padding 50px 0
    color $white
    .how-it-works-flex
        display flex
        flex-flow row wrap
        .how
            width 50%
            +below(1350px)
                width 100%
            h2
                text-align center
            .how-icons
                display flex
                flex-flow row wrap
                justify-content center
                +below(600px)
                    flex-flow column nowrap
                    align-items center
                .icon
                    padding-right 25px
                    display flex
                    flex-direction column
                    align-items center
                    p
                        margin-top 10px
                        font-size 14px
                        text-align center
                        font-weight 600
                    .green
                        color $leaseGreen
        .why
            width 50%
            +below(1350px)
                width 100%
                h2
                    text-align center
            .why-list
                +below(1350px)
                    padding-top 20px
                    width fit-content
                    margin auto
                padding-inline-start 30px
                li
                    padding-bottom 10px

.associate-callout
    padding 50px 0
    .callout
        h3, .hours
            color $leasePurple
            text-align center
        .desktop-link
            color $leaseGreen
            font-size 40px
            font-weight 600
            +below(991px)
                display none
        .mobile-link
            color $white
            background-color $leaseGreen
            border-radius 10px
            padding: 15px 30px
            font-weight 600
            font-size 20px
            +above(991px)
                display none
        .hours
            margin-top 5px
            font-weight 600
        .desktop-link
            &:hover
                text-decoration none
        display flex
        flex-direction column
        align-items center

.footer
    background-color $leaseGreen
    padding 15px
    .link-list
        padding-inline-start 0
        margin-bottom 0
        display flex
        justify-content center
        list-style none
        +below(1300px)
            flex-flow column nowrap
            align-items center
        li,a
            color $white
            font-size 20px
            font-weight 600
            .slash
                margin 0 10px
                +below(1300px)
                    display none
            a:hover
                text-decoration none
                color $leasePurple
            +below(500px)
                a
                    font-size 16px

.vendor-registration
    padding 100px 0

@keyframes spinny {
    from {
        transform: rotate(0deg);
    }
    to {
        transform rotate(360deg);
    }
}

.loader-1
    position fixed !important
    height 100%
    width 100%
    top 0
    left 0
    background RGBA(97,97,80,0.55)
    z-index 1000
    display flex
    align-items center
    justify-content center
    text-align: center
    .loader-content
        background-color RGBA(255,255,255,0.70)
        padding 50px 25px
        border-radius 20px
    &:empty::before,
    .spinner
        font-size 100px
        display inline-block
        content: ''
        width: 1em
        height: 1em
        border-radius 100%
        border solid transparent 5px
        border-left-color green
        animation-name spinny
        animation-duration 1s
        animation-iteration-count infinite

// form styles from main site
label
    font-size responsive 1rem 1.25rem
    font-range 768px 1199px
    text-transform uppercase
    .icon-star
        color #93a8c4
        &.green
            color $green
    font-family $font-stack-headers
input[type="text"], input[type="email"], input[type="password"]
    background-color transparent
    border-left 0
    border-right 0
    border-top 0
    border-radius 0
    border-bottom 2px solid $green
    color $violet
    display block
    font-size 1rem
    line-height 1.5
    overflow hidden
    padding 6px 12px
    position relative
    width 100%
    transition-all()
    &:focus
        outline 0
        transition-all()
    &::placeholder
        color $green
textarea
    border 2px solid $green
    border-radius 0
    color $violet
    min-height 220px
    padding 2px 8px
    width 100%
    &:focus
        outline-color $green
select
    border 2px solid $green
    border-radius 0
    background-image none
    color $violet
    width 100%
    padding 6px 12px
    &:focus
        outline-color $green
.buttons
    input, button
        cursor pointer
    .update
        margin-top 30px
.field
    margin-bottom 10px
.new-pass, .pass-descrip
    margin-bottom 10px

form
    .input
        margin-bottom 20px
    .finance
        display flex
        flex-direction row
        +between(768px, 991px)
            flex-direction column
        +below(575px)
            flex-direction column
        label
            margin-right 30px
    hr
        margin-top 1.5rem
        margin-bottom 1.5rem
    .ttip
        display block
        background rgba(255,0,0,.5)
        color rgb(205, 0, 0)
        text-indent 15px
        &:after
            display inline-block
            content '?'
            margin 0 10px
            width 20px
            background rgb(205,0,0)
            border-radius 100%
            color #fff
            line-height 20px
            text-align center
            text-indent 0

.row--callout
    width: 100%
    max-width: 600px
    align-items: center
    text-align: center
    &__col
        padding: 1rem
